import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    changeTo(e){
        let sectionId = e.target.dataset.sectionId;

        //force select option
        let options = this.element.querySelectorAll(".section_options");
        for (var i = 0; i < options.length; i++) {
            options[i].selected = '';
        }
        let option = document.getElementById("section_option_" + sectionId);
        option.selected = 'selected';

        //select tab css
        this.selectSection(sectionId);
    }

    changeToSelector(e){
        let selectedIndex = e.currentTarget.selectedIndex;
        let sectionId = e.currentTarget[selectedIndex].dataset.sectionId;
        this.selectSection(sectionId);
    }

    selectSection(sectionId){
        //titles
        let tab_titles = this.element.querySelectorAll(".section_title");
        for (var i = 0; i < tab_titles.length; i++) {
            this.deSelectElement(tab_titles[i]);
        }
        let section_title_element = document.getElementById("section_title_" + sectionId);
        this.selectElement(section_title_element);

        //bodies
        let tab_bodies = this.element.querySelectorAll(".section_body");
        for (var i = 0; i < tab_bodies.length; i++) {
            tab_bodies[i].classList.add("hidden");
        }
        let section_body_element = document.getElementById("section_body_" + sectionId);
        section_body_element.classList.remove("hidden");
    }

    deSelectElement(element){
        element.classList.remove("tab_selected");
        element.classList.add("tab_not_selected");
    }
    selectElement(element){
        element.classList.remove("tab_not_selected");
        element.classList.add("tab_selected");
    }

}
