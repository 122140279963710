// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import { fromJSON } from "postcss";
import "./controllers"

import Alpine from "alpinejs";
window.addEventListener("DOMContentLoaded", () => Alpine.start());

import jquery from 'jquery' 
window.jQuery = jquery
window.$ = jquery