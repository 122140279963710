import { Controller } from "@hotwired/stimulus" 
import Uppy from '@uppy/core'
import DragDrop from '@uppy/drag-drop'
import Dashboard from '@uppy/dashboard';
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'

import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';

export default class extends Controller {

  connect() {
    console.log("attachment-uppy-controller connect"); 
  }

  initialize(){
    
    console.log("initializing...");

    let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
    
    const uppy = new Uppy({
        id: 'uppy',
        autoProceed: true,
        debug: true,
        allowMultipleUploadBatches: true,
        restrictions: {
            maxNumberOfFiles:10,
            allowedFileTypes: ['.png','.jpg','.jpeg','.docx','.doc','.pdf']
        },
    });

    uppy.use(ActiveStorageUpload, {
        directUploadUrl: direct_upload_url
    })

    uppy.use(Dashboard, { 
        inline: true, 
        target: "#" + this.element.dataset.uploaderTarget,
        width: '100%',
        height: '300px',
        note: "PNG, JPG, PDF. 10MB per fitxer",
        showSelectedFiles: true,
        showRemoveButtonAfterComplete: true,
        disableThumbnailGenerator: true,
        locale: {
            strings:{
                dropPasteFiles: 'Arrosega els fitxers aquí o %{browseFiles}',
                browseFiles: 'cerca\'l a l\'explorador',
            }
        },
    });

    uppy.on('file-removed', (file, reason) => {
        if (reason === 'removed-by-user') {
            this.sendDeleteRequestForFile(file);
        }
    });

    /*
    uppy.use(DragDrop, { 
        target: '#upload-files-container',
        width: '100%',
        height: '100%',
        locale: {
            strings:{
                dropHereOr: "Adjunta els fitxers o arrosega'ls aquí",
            }
        },
    });
    */
    
    uppy.on('complete', (result) => {
        let fileFieldName = this.element.dataset.fileFieldName;
        result.successful.forEach(file => {
          this.appendUploadedFile(this.element, file, fileFieldName + "[]");
        })
    })

    /*
    https://github.com/transloadit/uppy/issues/1112#issuecomment-432339569
    uppy.getFiles().forEach(file => {
        uppy.setFileState(file.id, { 
          progress: { uploadComplete: true, uploadStarted: false } 
        })
      })
    */

  }

    sendDeleteRequestForFile(file){
    }

    appendFileToList(element, file){
    }

    appendUploadedFile(element, file, field_name) {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'text')
        hiddenField.setAttribute('name', field_name)
        hiddenField.setAttribute('data-pending-upload', true)
        hiddenField.setAttribute('value', file.response.signed_id)
        element.appendChild(hiddenField)
    }

}