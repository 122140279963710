import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["panel"]

  connect() {
    //console.log("side-panel-controller connect");
  }

  hidePanel() {
      this.element.parentElement.removeAttribute("src"); // it might be nice to also remove the modal SRC
      this.element.remove();
    }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    //console.log("submitEnd");
    //console.log(e.detail);
    if (e.detail.success) {
      this.hidePanel();
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hidePanel();
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.panelTarget.contains(e.target)) {
      return
    }
    this.hidePanel();
  }

}
