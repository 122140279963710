import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("inbox-controller connect");
  }

  messageRead(e){
    //console.log("message_read");
    //console.log(e);
  }
}