import { Controller } from "@hotwired/stimulus"

let alertTimer = 0;

export default class extends Controller {

    hideAlert() {
        this.element.remove();
    };

}