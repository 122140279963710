import { Controller } from "@hotwired/stimulus";
import {
    getMetaValue,
    findElement,
    removeElement,
    insertAfter,
  } from "../helpers/dropzone";
  
  import { Dropzone } from "dropzone";
  Dropzone.autoDiscover = false;

export default class extends Controller {

    connect(){
        console.log("user-contact-form-controller connect");
    }

    initialize(){
        
        console.log("initializing...");

        let myDropzone = new Dropzone(".dropzone", {
                            headers: { 
                                "X-CSRF-Token": getMetaValue("csrf-token"),
                            },
                            method: "POST",
                            credentials: "same-origin",
                            autoProcessQueue: false,
                            previewTemplate: document.querySelector('#upload-register').innerHTML,
                            previewsContainer: "#uploads-preview-container",
                            disablePreviews: false,
                            addRemoveLinks: false,
                            createImageThumbnails: false,
                            uploadMultiple: true,
                            parallelUploads: 100,
                            maxFiles: 10,
                            maxFilesize: 100, // MB
                            dictDefaultMessage: '',
                            clickable: "#dropzone_link",
                            init: function () {
                                this.on("success", function (file) {
                                    console.log("success > " + file.name);
                                });
                                console.log("CSRF: " + getMetaValue("csrf-token"));
                            }
                        });

        myDropzone.on('complete', function(file, response) {
            // reload the table
            //$('.dropzone').trigger('submit');
        });

        myDropzone.on("sendingmultiple", function () {
        });

        myDropzone.on("successmultiple", function (files, response) {
          console.log("successmultiple");
          console.log(response);
          document.getElementById("form-container").classList.add("hidden");
          document.getElementById("message-container").classList.remove("hidden");
        });

        myDropzone.on("errormultiple", function (files, response) {
        });

        myDropzone.on("addedfile", file => {
            if(myDropzone.files.length == 1){
            $("#dropzone_info").addClass("hidden");
            }
        });

        myDropzone.on("removedfile", file => {
            if(myDropzone.files.length == 0){
                $("#dropzone_info").removeClass("hidden");
            }
        });

        this.element.querySelector("button[type=submit]").addEventListener("click", function (e) {
            if (myDropzone.getQueuedFiles().length > 0) {
                e.preventDefault();
                e.stopPropagation();
                myDropzone.processQueue();
            } else {
                //no attachments
            }
        });

    }

}