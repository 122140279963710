import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    initialize(){

        const btn = this.element.querySelector('.search_by_combination');
        btn.addEventListener('click', e => {
            e.preventDefault();
            let from = this.element.querySelector(".language_from").value;
            let into = this.element.querySelector(".language_into").value;
            this.searchNow(from, into);
        });

    }

    triggerSearch(e){
        let selectedIndex = e.currentTarget.selectedIndex;
        let languageInto = e.currentTarget[selectedIndex].value;
        let from = this.element.querySelector(".language_from").value;
        this.searchNow(from, languageInto);
    }

    searchNow(from, into){
        console.log("redirecting...");
        let locale = this.element.querySelector(".active_locale").value;
        let url = "/" + locale + "/traductor/" + from + "-" + into
        window.location.href = url;
    }

}
